import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for routing
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import '../assets/styles/responsive.css';
import '../assets/styles/style.css';

const IndexPage = () => {
  const navigate = useNavigate(); // React Router hook for navigation

  const handleNavigation = (path) => {
    navigate(path); // Navigate to the specified path
  };

  return (
    <>
      <section className="slider_section">
        <div className="container">
          <div className="row align-items-center">
            {/* Text column - Will be on the left on large screens */}
            <div className="col-lg-6 col-md-12">
              <div className="detail-box">
                <h1>
                  Ihr zuverlässiger <br />
                  Partner für Heizung <br />
                  und Sanitär!
                </h1>
                <p>
                  Ganz gleich, ob es sich um Neubauten, Renovierungen oder Wartungsarbeiten handelt - ich stehe Ihnen zur Verfügung! Ich offeriere Ihnen maßgeschneiderte Lösungen im Bereich Heizung und Sanitärtechnik.
                </p>
                <button onClick={() => handleNavigation('/contact')} className="btn btn-primary">
                  Kontaktieren Sie uns!
                </button>
              </div>
            </div>
            
            {/* Image column - Will be on the right on large screens */}
            <div className="col-lg-6 col-md-12 text-center">
              <img 
                src="../../images/logoMain.jpg" 
                className="img-fluid" 
                alt="Company Logo" 
                style={{ maxWidth: '90%', marginTop: '5px',marginInlineStart:'21px' ,marginBottom:'69px'}} // Optional styling for responsiveness
              />
            </div>
          </div>
        </div>
      </section>

      <section className="feature_section">
        <div className="container">
          <div className="feature_container">
            <div className="box" onClick={() => handleNavigation('/services')} style={{ cursor: 'pointer' }}>
              <div className="img-box">
                <img src="../../images/heater.png" alt="Heizung" />
              </div>
              <h5 className="name">HEIZUNG</h5>
            </div>

            <div className="box" onClick={() => handleNavigation('/services')} style={{ cursor: 'pointer' }}>
              <div className="img-box">
                <img src='../../images/s3.png' alt='Sanitär' />
              </div>
              <h5 className="name">Sanitär</h5>
            </div>
          </div>
        </div>
      </section>

      <section className="about_section layout_padding-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-6">
              <div className="detail-box">
                <h2>Über uns</h2>
                <p>Bei Firma Simnica sind wir auf die Wartung und Reparatur von Heizungen und Sanitäranlagen spezialisiert. Unser Meister sorgt das ganze Jahr über mit zuverlässigem und effizientem Service für Ihren Komfort und Ihre Sicherheit.</p>
                <button onClick={() => handleNavigation('/about')} className="btn btn-primary">
                  Erfahren Sie mehr
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="professional_section layout_padding">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="img-box">
                <img src="../../images/professional-img.png" alt="Professionell" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="detail-box">
                <h2>
                  WIR BIETEN PROFESSIONELLE <br />
                  HAUSDIENSTLEISTUNGEN 
                </h2>
                <p>Ich bin auf professionelle Hausdienste spezialisiert, die die Wartung von Heizungen,  und Wassersystemen umfassen. Ich sorge dafür, dass Ihre Systeme effizient und zuverlässig laufen. Ich bin bestrebt, erstklassigen Service zu bieten und sicherzustellen, dass Ihr Zuhause das ganze Jahr über komfortabel bleibt. Vertrauen Sie mir all Ihre Wartungsbedürfnisse an.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IndexPage;
